/**
 * Sticky Nav
 */

 .icon {
    display: inline-block;
    fill: $text-color;
    height: 1em;
    stroke-width: 0;
    stroke: $text-color;
    width: 1em;
  }
  .c-topic-bar ul {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  #sticky-nav {
    max-width: $content-wide-width;
    margin: auto;
    font-weight: 700;
    // text-align: center;
    @include relative-font-size(0.925);
    background-color: $background-color;
    opacity: 0.9;
    padding: $gutter-quarter 0;
    border-bottom: 1px solid $text-color !important;
    border-top: 1px solid $text-color !important;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1000;
    top: -2px;
  }

  .c-topic-bar {
    li {
        margin: $gutter;
        flex-grow: 1;

        a {
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
        @include media-query($on-mobile) {
         flex-shrink: 0;
            }
      }
    li:first-child {
        // margin: $gutter $gutter $gutter 0;
    }
  }

  .c-topic-bar__inner {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.c-topic-bar__inner::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .c-topic-bar__inner {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .c-topic-bar:after {
    @include media-query($on-mobile) {
        background: linear-gradient(to right,rgba(255,255,255,0) 0,$background-color 100%);
    content: '';
    height: 100%;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 5rem;
        }
   
  }

  


  

