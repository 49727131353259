@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

// Define defaults for each variable.

$base-font-family: Montserrat, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$title-font-family: Montserrat, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.55 !default;
$line-height-title: 1.2;
$line-height-subtitle: 1.3;

// Spacing
$gutter: 1.25rem;

$gutter-half: $gutter * 0.5;
$gutter-third: $gutter * 0.75;
$gutter-quarter: $gutter * 0.25;
$gutter-eighth: $gutter * 0.125;

$gutter-double: $gutter * 2;
$gutter-quad: $gutter * 4;
$gutter-octo: $gutter * 8;

$spacing-unit:     20px !default;

$text-color:       #333!default;
$background-color: #f9f8f7 !default;
$brand-color:      #883C82!default;
$color-offwhite: hsl(0, 0%, 95%);

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;
$content-wide-width:    1200px !default;

$on-mobile:        544px !default;
$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin transition($transition...) {
  transition: $transition;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "minima/sticky-nav"
  // "bar-chart/bar-chart"
;
