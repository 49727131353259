/**
 * Site header
 */

 .scroll {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-color;
  @include media-query($on-mobile) {
    padding-top: 10px;
  }
}

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 300; 

  @include media-query($on-mobile) {
    display: block;
    font-size: 1.7rem;
  }
}

.scrolling-words-box {
  height: 3rem;
  margin: auto;
  overflow: hidden;
  
  ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: none;
    @media ( prefers-reduced-motion: no-preference ) {
      animation: scrollUp 10s infinite;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      width: 100%;
      height: 3rem;
      font-weight: 700; 
      list-style: none;
      color: $brand-color;
      @include media-query($on-mobile) {
        justify-content: center;
      }
      // color: #333;
      // margin: 0px -5px;
      // padding: 0px 5px;
      // background-color: #bcdcf7;
      // light pink with litte opacity background-color: rgba(245, 40, 145, 0.2);
      // blue #bcdcf7
      // light purple #e8b6ff
    }
  }
}

// Variables
$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}
.bold {
  font-weight: 700;
  margin-left: 10px;
}

.site-header {
  min-height: $spacing-unit * 1.865;
  margin-top: $gutter;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(3.125);

  @include media-query($on-mobile) {
    @include relative-font-size(2.625);
}
  font-family: $title-font-family;
  font-weight: 700;
  line-height: $line-height-title;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  text-align: center;
  a {
    text-decoration: none!important;
  }


  &:hover {
    text-decoration: underline;
  }

  &:hover,
  &:visited {
    color: $text-color;
  }


}

.sub-title {
  @include relative-font-size(1.625);
  font-family: $title-font-family;
  font-weight: 700;
  margin-top: $gutter-quarter;
  line-height: $line-height-subtitle;
  text-align: center;
}

.sub-subtitle {
  font-weight: 700;
  @include relative-font-size(1.25);
  margin-top: $gutter-double;
  margin-bottom: $gutter;
  line-height: $line-height-subtitle;
  color: $brand-color;
}

.about-me {
  margin-bottom: $gutter-double;
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

.section-title {
  margin-top: $gutter-double;
  color: $brand-color;
  margin-bottom: $gutter;
}

/**
 * Portfolio work grid
 */

 .work {
   margin-bottom: $gutter-double;
 }

 .work-grid {
  display: grid;
  grid-gap: $gutter-double;
  margin-top: $gutter;
  grid-template-columns: 1fr 1fr 1fr;

  @include media-query($on-mobile) {
    grid-template-columns: 1fr;
  }
    
 }

.work-title, .experience-title {
  @include relative-font-size(1.05);
  line-height: $line-height-subtitle;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: $gutter-quarter;

  @include media-query($on-mobile) {
    @include relative-font-size(1.2);
  }
}

.work-title {
  margin-bottom: $gutter-half;
}

.experience-title {
  margin-bottom: 0;
}

.view-link {
  text-decoration: underline;
  font-weight: 500;
  margin-top: $gutter-half;
}

.footer-link {
  cursor: pointer;
  text-decoration: none;
}


.work-link, .experience-link {
  // display: block;
  text-decoration: none;

  &:hover {
    color: $text-color;
    text-decoration: underline;


    .work-company {
      text-decoration: underline;
    }
    img {
        opacity: 0.7;
    }
  }

}

.experience-link {
  text-decoration: underline;
}

.skills-roles {
  @include relative-font-size(0.85);
  widows: 2;
  margin-top: $gutter-half;
}

.skills {
  background-color: rgba(136, 60, 130, 0.1);
  color: $brand-color;
  margin: 0px 2px;
  padding: 0px 5px;
  white-space: nowrap;
}

.skills-title {
 font-weight: 700;
 color: $brand-color;
}


.work-image {
  margin-bottom: $gutter-half;
  // margin-top: 5px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(204,204,204,0.3);
  -moz-box-shadow: 0px 0px 5px 2px rgba(204,204,204,0.3);
  box-shadow: 0px 0px 5px 2px rgba(204,204,204,0.3);

  &:hover {
    opacity: 0.7;
  }
}

.date-company {
  margin-bottom: $gutter-quarter;
  display: flex;
  font-weight: 700;
}

.work-description, .experience-description {
  @include relative-font-size(0.85);
  widows: 2;
}

.experience-description {
  margin-top: $gutter-quarter;
}

.awards-description {
  margin-bottom: $gutter-half;
  @include media-query($on-mobile) {
    margin-bottom: $gutter;
  }
}

.work-company {
  font-size: $small-font-size;
  color: $grey-color;
  float: right;
  &:hover {
    color: $text-color;
    text-decoration: underline;
    img {
        opacity: 0.7;
    }
  }
}

/**
* Resume Section
*/

.resume-experience-post {
  margin-bottom: $gutter-double;
}


/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit/2 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list, .c-topic-bar__inner {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  display: flex;
  @extend %clearfix;
}

.footer-col {
  flex: 1;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  margin-top: $gutter-double;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta, .resume-date {
  font-size: $small-font-size;
  color: $grey-color;
  font-weight: 300;
  flex: 1;
  text-transform: uppercase;
}

.post-date {
  text-align: right;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
